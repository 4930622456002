<template>
  <div class="view-wrapper channels-view">
    <ViewHeader :title="$store.getters.currentPlan.name">
      <template #actions>
        <Button
          label="Add Tactic"
          icon="pi pi-plus-circle"
          class="p-mr-2"
          @click="onAddTactic"
        />
        <Button
          label="Filters"
          icon="pi pi-sliders-h"
          class="p-button-outlined"
          @click="showTacticFiltersView = true"
          :badge="$store.getters.activeFiltersCount"
          badgeClass="p-badge-info"
        />
      </template>
      <template>
        <div class="p-d-flex p-ai-end">
          <DateRangePicker :isRangeView="true" :isDisabled="shouldShowUnscheduled" />

          <div class="p-field p-d-flex p-ml-4 p-mb-2 p-ai-center">
            <label for="shouldShowUnscheduled" class="p-mr-2">Scheduled Tactics</label>
            <InputSwitch id="shouldShowUnscheduled" v-model="shouldShowUnscheduled" />
            <label for="shouldShowUnscheduled" class="p-ml-2">Parking Lot</label>
            <span
                v-tooltip.bottom="'The Parking Lot holds unscheduled Tactic ideas for the future. Simply select &quot;Parking Lot&quot; when creating or editing a Tactic. They will be saved under the Table view, Parking Lot. Turn off the designation when you are ready to schedule.'"
                class="pi pi-question-circle tooltip-color p-ml-2 p-mb-2"
              ></span>
          </div>

          <div class="p-ml-auto p-mt-3">
            <!-- BEGIN: Archiving TacticImport.vue - JV 04.18.23 -->
            <!-- <Button
              v-if="selectedType.id"
              label="Import Tactics"
              icon="pi pi-upload"
              class="import-export-tactics-button p-mr-3"
              @click="onClickImportTactics()"
            /> -->
            <!-- <Button
              v-if="selectedType.id && canUserExportTactics"
              label="Export Tactics"
              icon="pi pi-download"
              class="import-export-tactics-button"
              @click="onClickExportTactics()"
            /> -->
            <!-- END: Archiving TacticImport.vue - JV 04.18.23 -->
          </div>
        </div>
      </template>
    </ViewHeader>
    <ViewMain>
      <DataTable
        v-if="currentTactics.length"
        v-model="currentTactics"
        :autoLayout="true" 
      >

      <template v-if="shouldShowUnscheduled">
        <!-- Parking Lot columns -->
        <Column
          field="title"
          header="Topic"
          headerClass="title-column"
          bodyClass="title-column"
          sortable
          key="parking_lot_title"
        >
          <template #body="slotProps">
            <span
              :class="{
                'is-nested': slotProps.data.isNested,
                'is-lead': slotProps.data.isLead,
              }"
            >
              {{ getTitleForTactic(slotProps.data) }}
            </span>
          </template>
        </Column>
        <Column field="channelName" header="Channel" sortable key="parking_lot_channel">
          <template #body="slotProps">
            {{ slotProps.data.channelName }}
          </template>
        </Column>
        <Column field="tacticTypeName" header="Tactic Type" sortable key="parking_lot_type">
          <template #body="slotProps">
            {{ slotProps.data.tacticTypeName }}
          </template>
        </Column>
        <template v-for="(tagCategory) in tagsInCategories">
          <Column
            :key="'parking_lot_' + tagCategory.name + tagCategory.id"
            :columnKey="tagCategory.name + tagCategory.id"
            :header="tagCategory.name"
            v-if="tagCategory.tags.length"
            sortable
          >
            <template #body="slotProps">
              <template v-if="getTagsForTagCategoryAndTactic(tagCategory, slotProps.data).length">
                <template v-for="(tag, index) in getTagsForTagCategoryAndTactic(tagCategory, slotProps.data)">
                  <span class="tag-title" :key="index">
                    {{ tag.title }}
                    <template v-if="index + 1 < getTagsForTagCategoryAndTactic(tagCategory, slotProps.data).length">
                      &nbsp;/&nbsp;
                    </template>
                  </span>
                </template>
              </template>
            </template>
          </Column>
        </template>
        <template v-if="$store.getters.currentPlan.isBudgetEnabled">
          <Column field="estimatedCost" header="Estimated Cost" key="parking_lot_estimated_cost"></Column>
          <Column field="actualCost" header="Actual Cost" key="parking_lot_actual_cost"></Column>
        </template>
        <Column field="summary" header="Notes" key="parking_lot_summary">
          <template #body="slotProps">
            <Button
                v-if="slotProps.data.summary !== null && slotProps.data.summary !== ''"
                icon="pi pi-eye"
                class="p-button-icon-only p-button-rounded p-button-text"
                @mouseenter="(event)=> {
                  currentOverlayHTML = slotProps.data.summary ? slotProps.data.summary : ''
                  $refs.richTextFieldOverlay.show(event)
                }"
                @mouseleave="(event)=> {
                  $refs.richTextFieldOverlay.hide()
                }"
              ></Button>
          </template>
        </Column>
        <Column key="parking_lot_open_button">
          <template #body="slotProps">
            <div
              class="p-d-flex p-jc-end"
              v-if="
                (isLeadPlan && slotProps.data.isLead) ||
                (!isLeadPlan && !slotProps.data.isLead)
              "
            >
              <Button
                label="Open"
                icon="pi pi-arrow-circle-up"
                class="p-button-text"
                @click="showTactic(slotProps.data)"
              ></Button>
            </div>
          </template>
        </Column>
        <!-- END Parking Lot Columns -->
      </template>
      <template v-else>
        <!-- Scheduled Tactics Columns -->
        <template v-if="shouldShowRepeatColumns">
          <!-- Has Repeat Column Content -->
          <Column
            field="title"
            header="Topic"
            headerClass="title-column"
            bodyClass="title-column"
            sortable
            key="scheduled_title_repeats"
          >
            <template #body="slotProps">
              <span
                :class="{
                  'is-nested': slotProps.data.isNested,
                  'is-lead': slotProps.data.isLead,
                }"
              >
                {{ getTitleForTactic(slotProps.data) }}
              </span>
            </template>
          </Column>
          <Column field="channelName" header="Channel" sortable key="scheduled_channel_repeats">
            <template #body="slotProps">
              {{ slotProps.data.channelName }}
            </template>
          </Column>
          <Column field="tacticTypeName" header="Tactic Type" sortable key="scheduled_type_repeats">
            <template #body="slotProps">
              {{ slotProps.data.tacticTypeName }}
            </template>
          </Column>
          <Column field="startDate" header="Start Date" sortable key="scheduled_start_repeats">
            <template #body="slotProps">
              {{ slotProps.data.startDate.toLocaleDateString() }}
            </template>
          </Column>
          <Column field="endDate" header="End Date" sortable key="scheduled_end_repeats">
            <template #body="slotProps">
              {{ slotProps.data.endDate.toLocaleDateString() }}
            </template>
          </Column>

          <template v-for="(customField, index) in universalCustomFieldsWithData">
            <Column
              :key="customField.name + customField.id + index + '_repeats'"
              :columnKey="customField.name + customField.id"
              :header="customField.name"
              sortable
            >
              <template #body="slotProps">
                {{ slotProps.data.typeValues.find((value) => value.tacticFieldId.intID === customField.id.intID )?.value }}
              </template>
            </Column>
          </template>

          <Column field="rrule" header="Repeats" key="scheduled_repeats_repeats">
            <template #body="slotProps">
              <template v-if="slotProps.data.rrule != null">
                {{ getRepeatsDisplayForTactic(slotProps.data) }}
              </template>
            </template>
          </Column>
          <Column field="recurrenceEndDate" header="End Repeat" key="scheduled_end_repeat_repeats">
            <template #body="slotProps">
              <template v-if="slotProps.data.rrule != null">
                {{slotProps.data.recurrenceEndDate ? slotProps.data.recurrenceEndDate.toLocaleDateString() : 'Never'}}
              </template>
            </template>
          </Column>
          <template v-for="(tagCategory, index) in tagsInCategories">
            <Column
              :key="tagCategory.name + tagCategory.id + index + '_repeats'"
              :columnKey="tagCategory.name + tagCategory.id"
              :header="tagCategory.name"
              v-if="tagCategory.tags.length"
              sortable
            >
              <template #body="slotProps">
                <template v-if="getTagsForTagCategoryAndTactic(tagCategory, slotProps.data).length">
                  <template v-for="(tag, index) in getTagsForTagCategoryAndTactic(tagCategory, slotProps.data)">
                    <span class="tag-title" :key="index">
                      {{ tag.title }}
                      <template v-if="index + 1 < getTagsForTagCategoryAndTactic(tagCategory, slotProps.data).length">
                        &nbsp;/&nbsp;
                      </template>
                    </span>
                  </template>
                </template>
              </template>
            </Column>
          </template>
          <template v-for="(type) in filteredInitiativesInCategories">
            <Column
              :key="type.id.intID + '_repeats'"
              :columnKey="type.id.intID"
              :header="type.name"
              sortable
            >
              <template #body="slotProps">
                {{ getInitiativeMappingForTypeAndTactic(type, slotProps.data).initiativeTitle }}
              </template>
            </Column>
          </template>
          <template v-if="$store.getters.currentPlan.isBudgetEnabled">
            <Column field="estimatedCost" header="Estimated Cost" key="scheduled_estimated_cost_repeats"></Column>
            <Column field="actualCost" header="Actual Cost" key="scheduled_actual_cost_repeats"></Column>
          </template>
          <Column header="Notes" field="summary" key="scheduled_summary_repeats">
            <template #body="slotProps">
              <Button
                  v-if="slotProps.data.summary !== null && slotProps.data.summary !== ''"
                  icon="pi pi-eye"
                  class="p-button-icon-only p-button-rounded p-button-text"
                  @mouseenter="(event)=> {
                    currentOverlayHTML = slotProps.data.summary ? slotProps.data.summary : ''
                    $refs.richTextFieldOverlay.show(event)
                  }"
                  @mouseleave="(event)=> {
                    $refs.richTextFieldOverlay.hide()
                  }"
                ></Button>
            </template>
          </Column>
          <Column key="scheduled_open_button_repeats">
            <template #body="slotProps">
              <div
                class="p-d-flex p-jc-end"
                v-if="
                  (isLeadPlan && slotProps.data.isLead) ||
                  (!isLeadPlan && !slotProps.data.isLead)
                "
              >
                <Button
                  label="Open"
                  icon="pi pi-arrow-circle-up"
                  class="p-button-text"
                  @click="showTactic(slotProps.data)"
                ></Button>
              </div>
            </template>
          </Column>
          <!-- END Has Repeat Column Content -->
        </template>
        <template v-else>
          <!-- Has No Repeat Column Content -->
          <Column
            field="title"
            header="Topic"
            headerClass="title-column"
            bodyClass="title-column"
            sortable
            key="scheduled_title"
          >
            <template #body="slotProps">
              <span
                :class="{
                  'is-nested': slotProps.data.isNested,
                  'is-lead': slotProps.data.isLead,
                }"
              >
                {{ getTitleForTactic(slotProps.data) }}
              </span>
            </template>
          </Column>
          <Column field="channelName" header="Channel" sortable key="scheduled_channel">
            <template #body="slotProps">
              {{ slotProps.data.channelName }}
            </template>
          </Column>
          <Column field="tacticTypeName" header="Tactic Type" sortable key="scheduled_type">
            <template #body="slotProps">
              {{ slotProps.data.tacticTypeName }}
            </template>
          </Column>
          <Column field="startDate" header="Start Date" sortable key="scheduled_start">
            <template #body="slotProps">
              {{ slotProps.data.startDate.toLocaleDateString() }}
            </template>
          </Column>
          <Column field="endDate" header="End Date" sortable key="scheduled_end">
            <template #body="slotProps">
              {{ slotProps.data.endDate.toLocaleDateString() }}
            </template>
          </Column>
          
          <template v-for="(customField, index) in universalCustomFieldsWithData">
            <Column
              :key="customField.name + customField.id + index + '_repeats'"
              :columnKey="customField.name + customField.id"
              :header="customField.name"
              sortable
            >
              <template #body="slotProps">
                {{ slotProps.data.typeValues.find((value) => value.tacticFieldId.intID === customField.id.intID )?.value }}
              </template>
            </Column>
          </template>

          <template v-for="(tagCategory) in tagsInCategories">
            <Column
              :key="tagCategory.name + tagCategory.id"
              :columnKey="tagCategory.name + tagCategory.id"
              :header="tagCategory.name"
              v-if="tagCategory.tags.length"
              sortable
            >
              <template #body="slotProps">
                <template v-if="getTagsForTagCategoryAndTactic(tagCategory, slotProps.data).length">
                  <template v-for="(tag, index) in getTagsForTagCategoryAndTactic(tagCategory, slotProps.data)">
                    <span class="tag-title" :key="index">
                      {{ tag.title }}
                      <template v-if="index + 1 < getTagsForTagCategoryAndTactic(tagCategory, slotProps.data).length">
                        &nbsp;/&nbsp;
                      </template>
                    </span>
                  </template>
                </template>
              </template>
            </Column>
          </template>
          <template v-for="(type) in filteredInitiativesInCategories">
            <Column
              :key="type.key"
              :columnKey="type.key"
              :header="type.name"
              sortable
            >
              <template #body="slotProps">
                {{ getInitiativeMappingForTypeAndTactic(type, slotProps.data).initiativeTitle }}
              </template>
            </Column>
          </template>
          <template v-if="$store.getters.currentPlan.isBudgetEnabled">
            <Column field="estimatedCost" header="Estimated Cost" key="scheduled_estimated_cost"></Column>
            <Column field="actualCost" header="Actual Cost" key="scheduled_actual_cost"></Column>
          </template>
          <Column header="Notes" field="summary" key="scheduled_summary">
            <template #body="slotProps">
              <Button
                  v-if="slotProps.data.summary !== null && slotProps.data.summary !== ''"
                  icon="pi pi-eye"
                  class="p-button-icon-only p-button-rounded p-button-text"
                  @mouseenter="(event)=> {
                    currentOverlayHTML = slotProps.data.summary ? slotProps.data.summary : ''
                    $refs.richTextFieldOverlay.show(event)
                  }"
                  @mouseleave="(event)=> {
                    $refs.richTextFieldOverlay.hide()
                  }"
                ></Button>
            </template>
          </Column>
          <Column key="scheduled_open_button">
            <template #body="slotProps">
              <div
                class="p-d-flex p-jc-end"
                v-if="
                  (isLeadPlan && slotProps.data.isLead) ||
                  (!isLeadPlan && !slotProps.data.isLead)
                "
              >
                <Button
                  label="Open"
                  icon="pi pi-arrow-circle-up"
                  class="p-button-text"
                  @click="showTactic(slotProps.data)"
                ></Button>
              </div>
            </template>
          </Column>
          <!-- END Has No Repeat Column Content -->
        </template>
        <!-- END Scheduled Tactics Columns -->
      </template>


        <!-- Media section hidden for MVP -->
        <!-- <Column field="impressions" header="Impressions"></Column>
        <Column field="ratingPoints" header="Rating Points"></Column> -->

        <!-- JV 05.29.24: Hiding columns for custom fields. -->
        <!-- <template v-for="field in currentTypeFields">
          <Column :key="field.key" :header="field.name">
            <template #body="slotProps">
              <button
                v-if="
                  field.type == 'image' &&
                  getFieldValueForTactic(field.id, slotProps.data)
                "
                class="custom-field-image-budgets-view"
                @click="
                  () => {
                    showCustomFieldImageDialog = true
                    customFieldDialogImage = getCustomImageFieldUrl(
                      slotProps.data.id,
                      field.id
                    )
                  }
                "
              >
                <img
                  :src="getCustomImageFieldUrl(slotProps.data.id, field.id)"
                  alt=""
                />
              </button>
              <span v-else-if="field.type === 'url'">
                <a
                  :href="getFieldValueForTactic(field.id, slotProps.data)"
                  target="_blank"
                  >{{ getFieldValueForTactic(field.id, slotProps.data) }}</a
                >
              </span>
              <span v-else-if="field.type === 'richtext'">
                <Button
                    icon="pi pi-eye"
                    class="p-button-icon-only p-button-rounded p-button-text"
                    @mouseenter="(event)=> {
                      currentOverlayHTML = getFieldValueForTactic(field.id, slotProps.data)
                      $refs.richTextFieldOverlay.show(event)
                    }"
                    @mouseleave="(event)=> {
                      $refs.richTextFieldOverlay.hide()
                    }"
                  ></Button>
              </span>
              <span v-else>
                {{ getFieldValueForTactic(field.id, slotProps.data) }}
              </span>
            </template>
          </Column>
        </template> -->

      </DataTable>
      
      <Dialog
        :dismissableMask="true"
        header=""
        :visible.sync="showCustomFieldImageDialog"
        :modal="true"
        @hide="() => (customFieldDialogImage = '')"
      >
        <div class="custom-image-dialog">
          <Button
            label="Download"
            class="p-button-lg p-button-outlined"
            icon="pi pi-download"
            @click="openInNewWindow(customFieldDialogImage)"
          />
          <img :src="customFieldDialogImage" alt="" />
        </div>
      </Dialog>

      <TacticFilters
        :showHide="showTacticFiltersView"
        @shouldHide="showTacticFiltersView = false"
      />
      
    </ViewMain>
    <div class="copyright-container">
      <p class="copyright">Copyright &copy; 2024 Annum. All rights reserved. United States Patent Number 12,079,779.</p>
    </div>

    <OverlayPanel ref="richTextFieldOverlay" class="rich-text-field-overlay" appendTo="body">
      <div class="notes-field-content-container" v-html="currentOverlayHTML"></div>
    </OverlayPanel>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Button from 'primevue/button'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import Dropdown from 'primevue/dropdown'
import ID from '@/models/ID'
import DateRangePicker from '@/components/DateRangePicker.vue'
import Channel from '@/models/Channel'
import TacticType from '@/models/TacticType'
import TacticPlatform from '@/models/TacticPlatform'
import Tactic from '@/models/Tactic'
import TacticField from '@/models/TacticField'
import TacticFieldValue from '@/models/TacticFieldValue'
import Tag from '@/models/Tag'
import {AlertMessageSeverity} from '@/models/AlertMessage'
import Role from '@/models/Role'
import { mapState } from 'vuex';
import {format, isSameDay, isThisISOWeek} from 'date-fns'
import RelatedInitiativeMapping from '@/models/RelatedInitiativeMapping'
import TagCategory from '@/models/TagCategory'
import InputSwitch from 'primevue/inputswitch'
import TacticFilters from '@/components/TacticFilters.vue'
import Tooltip from 'primevue/tooltip'

Vue.component('Button', Button)
Vue.component('Column', Column)
Vue.component('DataTable', DataTable)
Vue.component('Dropdown', Dropdown)

export default Vue.extend({
  name: 'Table',
  
  components: {
    ViewHeader,
    ViewMain,
    DateRangePicker,
    TacticFilters,
  },
  directives: {
    tooltip: Tooltip,
  },
  data: () => {
    return {
      showCustomFieldImageDialog: false as boolean,
      customFieldDialogImage: '' as string,
      customImageFieldUrlsMap: [] as {
        tacticId: number | string;
        fieldId: number | string;
        url: string;
      }[],
      currentOverlayHTML: '' as string,
      shouldShowUnscheduled: false as boolean,
      showTacticFiltersView: false as boolean,
    }
  },
  computed: {
    currentTypes(): TacticType[] {
      return this.$store.getters.selectedTacticTypes
    },
    currentTypeFields(): TacticField[] {
      let returnTacticFields = [] as TacticField[]
      this.currentTypes.forEach((type)=>{
        returnTacticFields = returnTacticFields.concat(type.tacticFields.map((field)=>field))
      })
      return returnTacticFields
    },
    currentTactics(): Tactic[] {
      const returnTactics = [] as Tactic[]
        this.currentTypes.forEach((type) => {
          // Get tactics along with clones for recurring tactics
          type.getTacticsWithClonesForDateRange(this.dateRange).forEach((tactic) => {
            if (
              !this.isTacticInFilteredOutPlan(tactic) && 
              !this.isTacticRelatedToDeselectedTag(tactic) &&
              !this.isTacticRelatedToDeselectedInitiative(tactic) &&
              !this.isTacticMonthlyFocusInDisabledChannel(tactic, type) &&
              (
                (this.shouldShowUnscheduled && tactic.isUnscheduled) || 
                (
                  (!this.shouldShowUnscheduled && !tactic.isUnscheduled) &&
                  (tactic.startDate <= this.dateRange[1] && tactic.endDate >= this.dateRange[0])
                )
              )
            ) {
              returnTactics.push(tactic)
            }
          })
      })
      return returnTactics
    },
    dateRange(): Date[] {
      const adjustedDateRangeStart = new Date(
        this.$store.getters.currentDateRange[0]
      )
      adjustedDateRangeStart.setHours(0, 0, 0, 0)
      const adjustedDateRangeEnd = new Date(
        this.$store.getters.currentDateRange[1]
      )
      adjustedDateRangeEnd.setHours(23, 59, 59, 999)
      return [adjustedDateRangeStart, adjustedDateRangeEnd]
    },
    canUserAddTactics(): boolean {
      return this.$store.getters.getPermissionLevelForPlanId(this.$store.getters.currentPlan.id) <=
        Role.LEVEL_CONTRIBUTOR
        ? true
        : false
    },
    canUserExportTactics(): boolean {
      return (
        this.$store.getters.currentAccountPermissionLevel <=
        Role.LEVEL_CONTRIBUTOR
      )
    },
    isLeadPlan(): boolean {
      return this.$store.getters.currentPlan.plans.length ? true : false
    },
    filteredInitiativesInCategories(): TacticType[] {
      const returnArray = [] as TacticType[]
      this.$store.getters.allInitiativesInCategories.forEach((type) => {
        const thisType = {...type}
        thisType.tactics = type.tactics.filter((initiative) => !this.isLeadPlan || (this.isLeadPlan && !initiative.isNested))
        returnArray.push(thisType)
      })
      return returnArray.sort((a,b) => a.orderIndex - b.orderIndex)
    },
    tagsInCategories(): TagCategory[] {
      return this.$store.getters.currentPlanTagCategories
    },
    shouldShowRepeatColumns(): boolean {
      return this.currentTactics.filter((tactic) => tactic.rrule !== '' && tactic.rrule !== null).length > 0 ? true : false
    },
    universalCustomFieldsWithData(): TacticField[] {
      return this.$store.getters.currentPlan.tacticFields.filter((field) => this.currentTactics.filter((tactic) => tactic.typeValues.filter((value) => value.tacticFieldId.intID === field.id.intID).length > 0).length > 0)
    },
  },
  mounted: function () {
    // Set date range to non-month range
    this.$store.dispatch('updateCurrentDateRange', this.$store.getters.rangeViewsDateRange)

    // Re-map image field URLs
    this.mapImageFieldUrls()
  },
  methods: {
    getInitiativeMappingForTypeAndTactic(type: TacticType, tactic: Tactic): RelatedInitiativeMapping {
      const thisMappingObj = tactic.relatedInitiatives.find((mappingObj: RelatedInitiativeMapping) => mappingObj.tacticTypeId.intID === type.id.intID)
      return thisMappingObj ? thisMappingObj : new RelatedInitiativeMapping()
    },
    getTagsForTagCategoryAndTactic(tagCategory: TagCategory, tactic: Tactic): Tag[] {
      return tagCategory.tags.filter((tag)=>tactic.tagIds.map((id)=>id.intID).includes(tag.id.intID))
    },

    isTacticInFilteredOutPlan(tactic: Tactic): boolean {
      if(tactic.isLead && !this.$store.getters.isLeadPlanVisible){
        return true
      }
      if(tactic.isNested && !this.$store.getters.visibleNestedPlansIntIds?.find((planIntId)=> planIntId === tactic.planId.intID)){
        return true
      }
      return false
    },
    isTacticRelatedToDeselectedTag(tactic: Tactic): boolean {
      if(this.$store.getters.isTagFilterActive){
        if (tactic.tags.length == 0) {
          //Tag filters are selected and tactic has no tags assigned
          return true
        }
        // Tags have been selected, show any tactics that have any selected tags
        return tactic.tags.filter(
            (tag) =>
              this.$store.getters.selectedTags.filter(
                (selectedTag) => selectedTag.id.intID == tag.id.intID
              ).length > 0
          ).length
            ? false
            : true
      }
      return false
    },
    isTacticRelatedToDeselectedInitiative(tactic: Tactic): boolean {
      const relatedInitiatives = tactic.relatedInitiatives.map((mapping: RelatedInitiativeMapping) => mapping.initiativeId.intID)
      if (this.$store.getters.isInitiativeFilterActive && relatedInitiatives.filter((intID) => intID === 0).length) {
        //Initiative filters are selected and tactic has no initiatives assigned
        return true
      }
      if (
        this.$store.getters.isInitiativeFilterActive &&
        this.$store.getters.selectedInitiatives.length == 1
      ) {
        //Only one initiative has been selected, show any tactics that have selected initiative
        return relatedInitiatives.filter(
          (intID) =>
            this.$store.getters.selectedInitiatives.filter(
              (selectedInitiative) => selectedInitiative.id.intID == intID
            ).length > 0
        ).length
          ? false
          : true
      }
      //Multiple initiatives are selected, show tactics that have all of the selected initiatives
      return relatedInitiatives.filter(
        (intID) =>
          this.$store.getters.deselectedInitiatives.filter(
            (deselectedInitiative) => deselectedInitiative.id.intID == intID
          ).length > 0
      ).length
        ? true
        : false
    },
    isTacticMonthlyFocusInDisabledChannel(tactic: Tactic, type: TacticType){
      if(type.name.toLowerCase() !== this.$store.getters.monthlyFocusTypeName){
        return false
      }

      const thisChannel = this.$store.getters.currentPlan.channels.find((channel) => channel.id.intID === type.channelId.intID)
      if(thisChannel && thisChannel.useMonthlyFocusType == false){
        return true
      }

      return false
    },

    onClickImportTactics() {
      this.$router.push({
        path: `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$store.getters.currentPlan.id.intID}/import`
      })
    },
    onClickExportTactics() {
      // TODO: table filtering/CSV export - export using selected channels/types from global filters panel
      // this.$store.getters.services.channels
      //   .exportChannel(this.selectedChannel.id, [this.selectedType.id], 'csv')
      //   .then(
      //     (response) => {
      //       const link = document.createElement('a')
      //       link.download = `AnnumExport-${this.selectedType.name
      //         .toLowerCase()
      //         .replaceAll(' ', '-')}.zip`
      //       link.href = response.url
      //       document.body.appendChild(link)
      //       link.click()
      //       document.body.removeChild(link)
      //     },
      //     (error) => {
      //       Vue.prototype.$toast.add({
      //         severity: AlertMessageSeverity.error,
      //         summary: 'Error exporting tactics.',
      //         detail: error,
      //       })
      //     }
      //   )
    },
    openInNewWindow(url) {
      if (url.substr(0, 10).indexOf('://') == -1) {
        url = `https://${url}`
      }
      window.open(url, '_blank')
    },
    getNameForInitiativeID: function (initiativeID: ID): string {
      let returnString = ''
      this.$store.getters.currentInitiatives.filter((tactic) => {
        if (tactic.id.intID == initiativeID.intID) {
          returnString = tactic.title
        }
      })
      return returnString
    },
    getFieldValueForTactic: function (fieldId: ID, tactic: Tactic): string {
      let returnString = ''
      if (tactic.typeValues.length) {
        const fieldValue = tactic.typeValues.find(
          (value) => value.tacticFieldId.intID == fieldId.intID
        )
        if (fieldValue) {
          returnString = fieldValue.value.toString()
        }
      }
      return returnString
    },
    showTactic: function (tactic: Tactic) {
      const thisTacticIntID = tactic.originalTacticID ? tactic.originalTacticID.intID : tactic.id.intID
      this.$router.push({path: 'tactic/' + thisTacticIntID})
    },
    onAddTactic: function () {
      // TODO: show error message if user is viewer
      if(this.canUserAddTactics){
          this.$router.push({
          path: `/account/${this.$store.getters.currentAccount.id.intID}/plan/${this.$store.getters.currentPlan.id.intID}/tactic/0`
        }) 
      }else{
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Viewers do not have the ability to add to or edit the Plan.',
          life: 3000,
        })
      }
    },
    mapImageFieldUrls() {
      this.currentTactics.forEach((tactic) => {
        tactic.typeValues.forEach((typeValue) => {
          const typeField = this.currentTypeFields.find(
            (typeField) => typeField.id.intID == typeValue.tacticFieldId.intID
          )
          if (typeField?.type == 'image' && typeValue.value) {
            this.$store.getters.services.tactics
              .getMediaAsset(typeValue.value)
              .then((response) => {
                this.customImageFieldUrlsMap =
                  this.customImageFieldUrlsMap.concat([
                    {
                      tacticId: tactic.id.intID,
                      fieldId: typeValue.tacticFieldId.intID,
                      url: response.fileLocation,
                    },
                  ])
              })
          }
        })
      })
    },
    getCustomImageFieldUrl(tacticId, fieldId) {
      const val = this.customImageFieldUrlsMap.find((imageField) => {
        if (
          imageField.fieldId == fieldId.intID &&
          imageField.tacticId == tacticId.intID
        ) {
          return true
        }
        return false
      })
      return val ? val.url : ''
    },
    getTitleForTactic(tactic: Tactic) {
      let returnString = tactic.title
      if (this.isLeadPlan && tactic.abbreviatedPlanName !== '') {
        if (returnString.length > 0) {
          returnString = tactic.abbreviatedPlanName + ' > ' + returnString
        } else {
          returnString = tactic.abbreviatedPlanName
        }
      }
      return returnString
    },
    getRepeatsDisplayForTactic(tactic: Tactic): string {
      // TODO: Copied from src/views/TacticSummary.vue, consider combining in to src/AppStore.ts
      const endDatePosition = tactic.rrule?.indexOf(';UNTIL')
      const rruleWithoutEndDate = endDatePosition != undefined && endDatePosition > -1 ? tactic.rrule?.substring(0, endDatePosition) : tactic.rrule
      const thisValue = this.getRepeatOptionsForTactic(tactic).find((option)=>option['value'] == rruleWithoutEndDate)
      return thisValue ? thisValue['label'] : 'Custom'
    },
    getRepeatOptionsForTactic(tactic: Tactic): object[] {
      // TODO: Copied from src/views/TacticDetail.vue, consider combining in to src/AppStore.ts
      const startDate = new Date(tactic.startDate)
      const year = startDate.getFullYear()
      const month =
        startDate.getMonth() > 8
          ? startDate.getMonth() + 1
          : '0' + (startDate.getMonth() + 1)
      const date =
        startDate.getDate() > 9
          ? startDate.getDate()
          : '0' + startDate.getDate()
      const hours =
        startDate.getHours() > 9
          ? startDate.getHours()
          : '0' + startDate.getHours()
      const minutes =
        startDate.getMinutes() > 9
          ? startDate.getMinutes()
          : '0' + startDate.getMinutes()
      const seconds =
        startDate.getSeconds() > 9
          ? startDate.getSeconds()
          : '0' + startDate.getSeconds()

      const dtStart = tactic.startDate
        ? `DTSTART=${year}${month}${date}T${hours}${minutes}${seconds};`
        : ''
      return [
        {
          value: `${dtStart}FREQ=DAILY`,
          label: 'Daily',
        },
        {
          value: `${dtStart}FREQ=WEEKLY;WKST=SU;BYDAY=${format(
            startDate,
            'EEEEEE'
          ).toUpperCase()}`,
          label: `Weekly on ${format(startDate, 'EEEE')}`,
        },
        {
          value: `${dtStart}FREQ=MONTHLY;BYMONTHDAY=${format(
            startDate,
            'd'
          )}`,
          label: `Monthly on the ${format(startDate, 'do')}`,
        },
        {
          ...this.getMonthlyByDay(startDate, dtStart),
        },
        {
          value: `${dtStart}FREQ=YEARLY`,
          label: `Annually on ${format(startDate, 'MMM')} ${format(
            startDate,
            'd'
          )}`,
        },
        {
          value: 'custom',
          label: 'Custom',
        },
      ]
    },
    getMonthlyByDay(
      startDate: Date,
      dtStart: string,
      onlyReturnMonthlyRule = false
    ) {
      // TODO: Copied from src/views/TacticDetail.vue, consider combining in to src/AppStore.ts
      const d = new Date(startDate.getTime())
      const dayOfWeek = d.getDay()
      const month = d.getMonth()
      const datesWithSameDayOfWeek = [] as Date[]

      // Get the first instance of the day of week in the month
      d.setDate(1)
      while (d.getDay() !== dayOfWeek) {
        d.setDate(d.getDate() + 1)
      }

      // get all instances of day of week
      while (d.getMonth() === month) {
        datesWithSameDayOfWeek.push(new Date(d))
        d.setDate(d.getDate() + 7)
      }

      // find our start date's index of those
      let indexOfStartDate = 0
      datesWithSameDayOfWeek.forEach((date, index) => {
        if (isSameDay(startDate, date)) {
          indexOfStartDate = index
        }
      })

      const isFifthInstanceOfWeekDay = indexOfStartDate > 4

      if (onlyReturnMonthlyRule) {
        return {
          value: isFifthInstanceOfWeekDay
            ? `BYSETPOS=-1;BYDAY=${format(startDate, 'EEEEEE').toUpperCase()}`
            : `BYSETPOS=${indexOfStartDate + 1};BYDAY=${format(
                startDate,
                'EEEEEE'
              ).toUpperCase()}`,
          label: isFifthInstanceOfWeekDay
            ? `Monthly on last ${format(startDate, 'eeee')} of the month`
            : `The ${indexOfStartDate + 1}${
                ['st', 'nd', 'rd'][
                  ((((indexOfStartDate + 1 + 90) % 100) - 10) % 10) - 1
                ] || 'th'
              } ${format(startDate, 'eeee')} of the month`,
        }
      }
      return {
        value: isFifthInstanceOfWeekDay
          ? `${dtStart}FREQ=MONTHLY;BYSETPOS=-1;BYDAY=${format(
              startDate,
              'EEEEEE'
            ).toUpperCase()}`
          : `${dtStart}FREQ=MONTHLY;BYSETPOS=${
              indexOfStartDate + 1
            };BYDAY=${format(startDate, 'EEEEEE').toUpperCase()}`,
        label: isFifthInstanceOfWeekDay
          ? `Monthly on last ${format(startDate, 'eeee')} of the month`
          : `Monthly on the ${indexOfStartDate + 1}${
              ['st', 'nd', 'rd'][
                ((((indexOfStartDate + 1 + 90) % 100) - 10) % 10) - 1
              ] || 'th'
            } ${format(startDate, 'eeee')} of the month`,
      }
    },
  },
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.channels-view {
  .tag-title {
    display: block;
  }
  .empty-message {
    height: 100%;
  }
  .p-datatable-thead > tr > th,
  .p-datatable-tbody > tr > td {
    max-width: 30rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .p-buttonset {
    .p-button {
      border-color: #efefef;
      background-color: #efefef;
      color: #333333;
      white-space: nowrap;

      &:disabled {
        border-color: #000000;
        background-color: #000000;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
  .type-field-container {
    // max-width: calc(100% - 15rem);
  }
  .platform-field-container {
    max-width: calc(100% - 15rem);
  }
  .import-export-tactics-button {
    min-width: 12rem;
  }
  .custom-field-image-budgets-view {
    border: 0;
    padding: 0;
    cursor: pointer;
    img {
      height: 50px;
    }
  }
  .p-datatable-wrapper span.is-lead {
    display: flex;
    column-gap: 1rem;

    &::before {
      content: '\e925'; // pi-home
      display: block;
      font-family: 'primeicons';
      font-size: 1.6rem;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
    }
  }
  .p-sortable-column {
    .p-sortable-column-icon {
      opacity: 0;
    }

    &:hover,
    &:focus {
      .p-sortable-column-icon {
        opacity: 1;
      }
    }
  }
  .p-inputswitch {
    .p-inputswitch-slider {
      background: green;

      &:hover {
        background: green;
      }
    }
    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: #666;

        &:hover {
          background: #666;
        }
      }
    }
  }
}
</style>
